const loadImg = async (url) => new Promise((res) => {
  const img = new window.Image();

  img.addEventListener('load', () => res(img));
  img.src = url;
});

export const getSvgDimensions = (svgString) => {
  const svgInDiv = document.createElement('div');

  svgInDiv.innerHTML = svgString;
  const svg = svgInDiv.getElementsByTagName('svg')[0];
  const viewBox = svg.viewBox.baseVal || {};

  return { width: viewBox.width, height: viewBox.height };
};


export const getSvgUrlAndDimensions = async (url) => {
  const res = await fetch(url);
  const svgString = await res.text();
  const DOMURL = window.URL || window.webkitURL;
  const svg = new Blob([svgString], { type: 'image/svg+xml' });

  return {
    url: DOMURL.createObjectURL(svg),
    ...getSvgDimensions(svgString),
  };
};


// eslint-disable-next-line no-async-promise-executor
export const loadImgWithDataUrl = async (url, dimensions) => {
  if (/(\.svg)$/.test(url)) {
    const details = await getSvgUrlAndDimensions(url);

    return loadImgWithDataUrl(details.url, dimensions || { width: details.width, height: details.height });
  }


  const img = await loadImg(url);
  const canvas = document.createElement('canvas');

  if (dimensions?.width && dimensions?.height) {
    img.width = dimensions.width;
    img.height = dimensions.height;
  }

  canvas.width = img.width;
  canvas.height = img.height;
  const context = canvas.getContext('2d');

  context.drawImage(img, 0, 0);

  return {
    dataUrl: canvas.toDataURL('image/png'),
    img,
  };
};


export default loadImg;
