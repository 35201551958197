const getToggleButton = (instance, { buttonImage, name, canExecute }) => {
  const { docViewer } = instance;

  const btn = document.createElement('div');
  btn.classList.add('Button', 'ActionButton');
  const img = document.createElement('img');
  img.src = buttonImage;
  btn.appendChild(img);

  btn.onclick = () => {
    // if button is disabld then dont do anything
    if (btn.classList.contains('disable')) {
      return;
    }

    if (canExecute) {
      const continueExecution = canExecute(instance);
      if (!continueExecution) {
        return;
      }
    }

    instance.setToolMode(name);
  };


  docViewer.on('toolModeUpdated', (newTool, oldTool) => {
    if (newTool.name === name) {
      btn.classList.add('active');
    }

    if (oldTool.name === name) {
      btn.classList.remove('active');
    }
  });


  return () => btn;
};


export default getToggleButton;
