import _ from 'lodash';
// Strip out the strikeout and whiteout annots.
export default function stripNonSpecialAnnots(xfdf) {
  if (_.isEmpty(xfdf)) {
    return '';
  }

  const doc = new DOMParser().parseFromString(xfdf, 'application/xml');
  const annots = doc.getElementsByTagName('annots');
  if (_.isEmpty(annots)) {

    return new XMLSerializer().serializeToString(doc);
  }

  Array.from(annots[0].childNodes).map(async (xmlNode) => {
    if (xmlNode.getAttribute('subject') === 'Strikeout' || xmlNode.getAttribute('subject') === 'WhiteOutAnnotation') {
      xmlNode.parentNode.removeChild(xmlNode);
    }
  })

  return new XMLSerializer().serializeToString(doc);
}
