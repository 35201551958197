/* eslint-disable no-multi-assign */
import * as R from 'ramda';
import _ from 'lodash';
import Promise from 'bluebird';
import debug from 'debug';
import { filterSignerProps } from './helpers';
import { v4 } from 'uuid';
import firebase from 'firebase/app';
import io from 'socket.io-client';


const log = debug('server');


/**
 *
 * @param {typeof firebase} firebase
 * @param {*} serverOpts
 * @returns
 */
export const initServer = async (firebase, serverOpts) => {
  class Server {
    constructor({
      nsId,
      userId,
      user,
      signers,
      signerLocation,
      token,
      rtdbNamespace,
      isNotarizeNow = false,
    }) {
      this.nsId = nsId;
      this.userId = userId;
      this.userType = user?.userType || 'signer';

      // the signers that are required
      this.requiredSigners = _.filter(signers, ({ userType, type }) => userType !== 'admin' && type !== 'remotesigner');

      this.user = user;
      this.userId = userId;
      this.isAdminUser = serverOpts.userType === 'admin';

      this.signerLocation = signerLocation;

      this.organizationId = rtdbNamespace;
      this.rtdbNamespace = _.isEmpty(rtdbNamespace) ? 'rooms' : `organization/${rtdbNamespace}/rooms`;


      // Initialize Firebase
      this.firebase = firebase;
      this.token = token;
    }


    init = async () => {
      const { nsId, userId } = this;

      if (this.token) {
        await this.signInWithToken(this.token);
      } else {
        await this.signInAnonymously();
      }


      const roomRef = (this.roomRef = firebase
        .database()
        .ref(this.rtdbNamespace)
        .child(nsId));


      // update the /organization/<orgId>/rooms/<nsId>/updatedAt value whenever updates happen
      this.roomRef.on('child_changed', (snapshot) => {
        // return if updatedAt is the key that was changed to not cause infinite loop
        if (snapshot.key === 'updatedAt') {
          return;
        }

        this.roomRef.update({
          updatedAt: Date.now(),
        });
      });

      this.selectedSignerRef = roomRef.child('selectedSigner');
      this.annotationsRef = roomRef.child('annotations');
      this.modifiedXfdf = roomRef.child('modifiedXfdf');
      this.widgetRef = roomRef.child('widgets');

      this.fieldRef = roomRef.child('fields');
      this.xfdfRef = roomRef.child('xfdf');
      this.connectionRef = firebase.database().ref('.info/connected');

      this.statusRef = roomRef.child('status');
      this.authorsRef = roomRef.child('authors');
      this.notaryRef = roomRef.child('notary');

      this.participantsRef = roomRef.child('participants');
      this.blankPagesRef = roomRef.child('blankPages');
      this.lockRef = roomRef.child('locked');
      this.vaDisclaimerRef = roomRef.child('vaDisclaimerShown');
      this.selectedDocIdRef = roomRef.child('selectedDocId');
      this.pageRef = roomRef.child('pageNumber');
      this.selectedDocTitleRef = roomRef.child('selectedDocTitle');
      this.vaDisclaimerRejectedRef = roomRef.child('vaDisclaimerRejected');
      this.completingRef = roomRef.child('isCompleting');
      this.sessionReloadedRef = roomRef.child('sessionReloadedAt');
      this.consumerSignatures = roomRef.child('consumerSignatures');

      /*
        TODO: As of V2-5462 the <AuthPinModal /> has been removed. The Firebase functions,
        listeners, and references that worked with this 'pinModalOpen' child node could be removed.
        I'm leaving them in at the moment just in case.
      */
      this.pinModalRef = roomRef.child('pinModalOpen');
      this.enoteConsentModalRef = roomRef.child('enoteConsentModal');
      this.authPinModalRef = roomRef.child('showAuthPinModal');
      this.statusRef = roomRef.child('status');
      this.loadedDocsRef = roomRef.child('loadedDocsRef');
      this.updatedAtRef = roomRef.child('updatedAt');

      await this.vaDisclaimerRef.set(false);

      this.refLists = {};
      this.initializedRefs = [];
      this.mainRefs = [];
      this.allRefs = [];
      this.eventMapping = {};

      return new Promise((res) => this.connectionRef.on('value', async (snapshot) => {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) {
          console.debug('%cfirebase not connected 🔥!', 'color: red; font-size:12px');

          return;
        }

        console.debug('%cfirebase connected 🔥!', 'color: blue; font-size:12px');

        // if im a signer and we're all on the same machine (ie consumer flow)
        if (!this.isAdminUser) {
          if (this.signerLocation === 'local') {
            // add presence for each signer
            await this.addPresences(this.requiredSigners);

            return res();
          }
        } else {
          const socket = this.socket = io('/presence', {
            path: '/api/websocket',
            autoConnect: false,
            transports: ['websocket'],
            reconnection: true,
            auth: {
              nsId: this.nsId,
              organizationId: this.organizationId,
              userId: this.userId,
            },
          });
          window.socket = socket;

        }

        return res();
      }));
    };


    /**
     * @returns {Promise<import('socket.io-client').Socket>}
     */
    connectSocket = () => {
      return new Promise((res, rej) => {
        this.socket.on('connect', () => {
          console.log('socket connected', this.socket.recovered)
          return res(this.socket);
        });

        this.socket.on("connect_error", (err) => rej(err));

        this.socket.connect();
      })
    }


    markAsDisconnected = () => this.userId && this.authorsRef
      .child(this.userId)
      .child('connected')
      .set(false);


    setShowVaDisclaimer = (val) => this.vaDisclaimerRef.set(val);

    setVaDisclaimerRejected = (val) => this.vaDisclaimerRejectedRef.set(val);


    /**
     *
     * @param {firebase.database.Reference} ref
     * @param {*} event
     * @param {*} callbackFunction
     * @param {*} unbindList
     * @param {*} eventName
     * @returns
     */
    createBinding = (ref, event, callbackFunction, unbindList = 'default', eventName) => {
      const initializedRef = ref.on(event, callbackFunction);


      const refList = (unbindList === 'main') ? this.mainRefs : this.initializedRefs;
      const rmRef = () => ref.off(event, callbackFunction);

      refList.push(rmRef);
      this.allRefs.push(rmRef);


      // initializedRef._name = event;
      this.refLists[unbindList] = this.refLists[unbindList] || [];
      this.refLists[unbindList].push(rmRef);



      if (eventName) {
        const id = v4();
        this.eventMapping[eventName] = this.eventMapping[eventName] || [];
        this.eventMapping[eventName].push({ id, ref, event, callback: callbackFunction });

        return id;
      }
    }

    unbindEvent = async (eventName, id) => {
      if (this.eventMapping[eventName]) {
        const toUnbind = _.find(this.eventMapping[eventName], (evt) => {
          return evt.id === id;
        })

        if (toUnbind) {
          toUnbind.ref.off(toUnbind.event, toUnbind.callback);
          this.eventMapping[eventName] = _.filter(this.eventMapping[eventName], (evt) => evt.id !== id);
        }
      }
    }


    unbindAll = async (unbindList = 'default') => {
      if (unbindList === 'all') {
        log('unbinding all refs');

        await Promise.map(_.toPairs(this.refLists), ([listName, refs = []]) => {
          log(`unbinding ${listName} refs`, refs.length);

          return Promise.map(refs, (unbind) => unbind());
        });
      } else if (_.isEmpty(this.refLists[unbindList])) {
        console.debug(`unbinding an empty list of refs: ${unbindList}`);
      } else {
        log(`new: unbinding ${unbindList} refs`, this.refLists[unbindList].length);
        await Promise.map(this.refLists[unbindList], (unbind) => unbind());
        this.refLists[unbindList] = [];
      }


      if (unbindList === 'main') {
        log('unbinding main refs', this.mainRefs.length);
        await Promise.map(this.mainRefs, (unbind) => unbind());
        this.mainRefs = [];
      } else if (unbindList === 'all') {
        log('unbinding all refs', this.allRefs.length);
        await Promise.map(this.allRefs, (unbind) => unbind());
        this.allRefs = [];
        this.mainRefs = [];
        this.initializedRefs = [];
        this.roomRef.off('child_changed');
      } else {
        log('unbinding initialized refs', this.initializedRefs.length);
        await this.annotationsRef.off();
        await this.widgetRef.off();
        await this.pageRef.off();
        await Promise.map(this.initializedRefs, (unbind) => unbind());
        if (this.socket) {
          this.socket.off('annotationCreated', this.onAnnotationCreated);
          this.socket.off('annotationUpdated', this.onAnnotationUpdated);
          this.socket.off('annotationDeleted', this.onAnnotationDeleted);
        }
        this.initializedRefs = [];
      }
    }

    bind = (action, docId, cbFunc = docId, unbindList = 'default') => {
      const callbackFunction = R.pipe(R.applySpec({ val: R.invoker(0, 'val'), key: R.prop('key') }), cbFunc);

      log(`binding: ${action}, ${unbindList}`);

      switch (action) {
        case 'onAuthStateChanged':
          return firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
              return cbFunc(user);
            }

            // Author is not logged in
            try {
              await this.signInWithToken(this.token);
            } catch (error) {
              console.error(error);
              throw error;
            }
          });


        case 'onPageChanged':
          this.pageInstRef = this.pageInstRef ? this.pageInstRef : this.pageRef
            .orderByKey()
            .equalTo(docId);

          return this.pageInstRef
            .on('value', callbackFunction);

        case 'onFieldAdded':
          return this.createBinding(this.fieldRef, 'child_added', callbackFunction, unbindList, action);
        case 'onFieldUpdated':
          return this.createBinding(this.fieldRef, 'child_changed', callbackFunction, unbindList, action);
        case 'onWidgetCreated':
          return this.createBinding(this.widgetRef, 'child_added', callbackFunction, unbindList, action);
        case 'onWidgetUpdated':
          return this.createBinding(this.widgetRef, 'child_changed', callbackFunction, unbindList, action);
        case 'onWidgetDeleted':
          return this.createBinding(this.widgetRef, 'child_removed', callbackFunction, unbindList, action);
        case 'onAnnotationCreated':
          return this.createBinding(this.annotationsRef.orderByChild('createdAt'), 'child_added', callbackFunction, unbindList, action);
        case 'onAnnotationUpdated':
          return this.createBinding(this.annotationsRef.orderByChild('createdAt'), 'child_changed', callbackFunction, unbindList, action);
        case 'onAnnotationDeleted':
          return this.createBinding(this.annotationsRef.orderByChild('createdAt'), 'child_removed', callbackFunction, unbindList, action);
        case 'onBlankPagesChanged':
          if (docId) {
            return this.createBinding(this.blankPagesRef.child(docId), 'value', callbackFunction, unbindList, action);
          }
          return true;
        case 'onSelectedSignerChanged':
          return this.createBinding(this.selectedSignerRef, 'value', callbackFunction, unbindList, action);

        case 'onParticipantsChanged':
          return this.createBinding(this.participantsRef, 'value', callbackFunction);
        case 'onUpdatedAtChanged':
          return this.createBinding(this.updatedAtRef, 'value', callbackFunction, unbindList, action);
        case 'onLockChanged':
          return this.createBinding(this.lockRef, 'value', callbackFunction, unbindList, action);
        case 'onVaDisclaimerChanged':
          return this.createBinding(this.vaDisclaimerRef, 'value', callbackFunction, unbindList, action);
        case 'onAuthorsChanged':
          return this.createBinding(this.authorsRef, 'value', callbackFunction, unbindList, action);
        case 'onAuthorConnected':
          return this.createBinding(this.authorsRef, 'child_added', callbackFunction, unbindList, action);
        case 'onAuthorDisconnected':
          return this.createBinding(this.authorsRef, 'child_removed', callbackFunction, unbindList, action);
        case 'onAuthorChanged':
          return this.createBinding(this.authorsRef, 'child_changed', callbackFunction, unbindList, action);
        case 'onConsumerSignaturesChanged':
          return this.createBinding(this.consumerSignatures, 'value', callbackFunction, unbindList, action);
        case 'onSelectedDocIdChanged':
          return this.createBinding(this.selectedDocIdRef, 'value', callbackFunction, unbindList, action);
        case 'onVaDisclaimerRejected':
          return this.vaDisclaimerRejectedRef.on('value', callbackFunction);
        case 'onSessionReloadedAt':
          return this.createBinding(this.sessionReloadedRef, 'value', callbackFunction, unbindList, action);
        case 'onCompletingChanged':
          return this.createBinding(this.completingRef, 'value', callbackFunction, unbindList, action);
        case 'onPinModalChanged':
          return this.createBinding(this.pinModalRef, 'value', callbackFunction, unbindList, action);
        case 'onAuthPinModalChanged':
          return this.createBinding(this.authPinModalRef, 'value', callbackFunction, unbindList, action);
        case 'onStatus':
          return this.createBinding(this.statusRef, 'value', callbackFunction, unbindList, action);
        case 'onStatusChanged':
          return this.createBinding(this.statusRef, 'value', callbackFunction, unbindList, action);
        case 'onLoadedDocsChanged':
          return this.createBinding(this.loadedDocsRef, 'value', callbackFunction, unbindList, action);

        default:
          log('The action is not defined.', action);
          break;
      }
    };

    setShowAuthPinModal = (val) => this.pinModalRef.set(val)
    getShowAuthPinModal = () => this.pinModalRef.once('value').then(R.invoker(0, 'val'))

    getFields = () => this.fieldRef.once('value')
      .then(R.invoker(0, 'val'))
      .then((fields) => _.mapKeys(fields, (val, key) => key.replace(/__/ig, ' ').replace(/_/ig, '.')))

    setField = (name, val) => this.fieldRef.child(name.replace(/ /ig, '__').replace(/\./ig, '_')).set(val)

    getAuthors = () => this.authorsRef.once('value').then(R.invoker(0, 'val'));

    updateAuthors = (authors) => this.authorsRef.set(authors);

    setAuthor = (authorId, authorName) => this.authorsRef
      .child(authorId)
      .child('authorName')
      .set(authorName);

    checkAuthor = (authorId, openReturningAuthorPopup, openNewAuthorPopup) => {
      this.authorsRef.once('value', (authors) => {
        if (authors.hasChild(authorId)) {
          this.authorsRef.child(authorId).once('value', (data) => {
            const val = data.val();

            openReturningAuthorPopup(`${val.firstName} ${val.lastName}`);
          });
        } else {
          openNewAuthorPopup();
        }
      });
    };

    setPageNumber = (docId, num = 1) => this.pageRef.child(docId).set(num);

    getPageNumber = (docId) => this.pageRef.child(docId).once('value')
      .then(R.pipe(R.invoker(0, 'val'), R.defaultTo(1)));

    setBlankPages = (docId, num = 0) => {
      if (_.isNaN(num)) {
        return;
      }

      return this.blankPagesRef.child(docId).set(num);
    }


    resetBlankPages = async () => {
      const currBlankPages = (await this.blankPagesRef.once('value').then(R.invoker(0, 'val')) || {});
      const newBlankPages = _.mapValues(currBlankPages, R.always(0));

      return this.blankPagesRef.set(newBlankPages);
    };

    getBlankPagesByDocId = (docId) => this.blankPagesRef.child(docId).once('value').then(R.invoker(0, 'val'))

    getBlankPages = () => this.blankPagesRef.once('value').then(R.invoker(0, 'val')) || {}

    createAuthors = (signers) => _.chain(signers)
      .map(({ firstName, lastName, id }) => ({
        id,
        fullName: `${firstName} ${lastName}`,
      }))
      .map(({ id, fullName }) => this.authorsRef
        .child(id)
        .child('authorName')
        .set(fullName))
      .thru((proms) => Promise.all(proms))
      .value()

    authenticate = () => new Promise((res) => {
      this.bind('onAuthStateChanged', (user) => ((user) ? res(user) : this.signInAnonymously()));
    })

    signInWithToken = async (token) => {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION);

      return firebase.auth().signInWithCustomToken(token);
    };

    signInAnonymously = async () => {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION);

      return firebase.auth().signInAnonymously();
    };

    removeAllXfdf = () => this.xfdfRef.set({});

    setSelectedSigner = (signerId) => this.selectedSignerRef.set(signerId || '-1');

    getSelectedSigner = () => this.selectedSignerRef.once('value').then(R.invoker(0, 'val'))

    clearAnnotations = () => this.annotationsRef.set({});

    clearWidgets = async () => {
      this.widgetRef.set({});
      // const allSigWigets = await this.widgetRef
      //   .orderByChild('subtype')
      //   .equalTo('SIGNATURE')
      //   .once('value')
      //   .then(R.pipe(R.invoker(0, 'val')));
      // const allInitialsWidgets = await this.widgetRef
      //   .orderByChild('subtype')
      //   .equalTo('INITIALS')
      //   .once('value')
      //   .then(R.pipe(R.invoker(0, 'val')));
      // const updateVal = _.mapValues({ ...allSigWigets, ...allInitialsWidgets }, R.always(null));

      // return this.widgetRef.update(updateVal);
    }

    clearAll = async () => Promise.all([
      this.annotationsRef.set({}),
      this.widgetRef.set({}),
    ])


    resetDoc = async (currSelectedDoc) => {
      await Promise.all([
        this.setShowVaDisclaimer(false),
        this.clearModifiedXfdf(),
      ]);
      const currAnnots = await this.annotationsRef
        .once('value')
        .then(R.invoker(0, 'val'));

      const nextAnnots = _.chain(currAnnots)
        .toPairs()
        .filter(([key, val]) => val.docId === currSelectedDoc)
        .map(([key]) => key)
        .thru((keysToOmit) => _.omit(currAnnots, keysToOmit))
        .value()

      const currLoadedDocs = await this.loadedDocsRef.once('value')
        .then(R.invoker(0, 'val'));

      const nextLoadedDocs = _.filter(currLoadedDocs, (el) => el !== currSelectedDoc);

      await this.annotationsRef.set(nextAnnots);
      await this.loadedDocsRef.set(nextLoadedDocs);

      await this.setSelectedDocId('-1');
      return this.setSelectedDocId(currSelectedDoc);
    }
    reloadDoc = async () => {
      const docId = await this.getSelectedDocId();
      await this.setSelectedDocId('-1');

      return this.setSelectedDocId(docId);
    }
    resetSession = async (currSelectedDoc, skipDeleteSignatures) => {
      if (!skipDeleteSignatures) {
        await this.deleteAllSignatures();
      }

      await Promise.all([
        this.setShowVaDisclaimer(false),
        this.resetBlankPages(),
        this.clearAll(),
        this.clearLoadedDocs(),
        this.clearModifiedXfdf(),
      ]);
      await this.setSelectedDocId('-1');

      return this.setSelectedDocId(currSelectedDoc);
    }


    createWidget = (widgetId, widgetData) => this.widgetRef.child(widgetId).set(widgetData);

    deleteWidget = (widgetId) => this.widgetRef.child(widgetId).remove();

    createAnnotation = (annotationId, annotationData) => this.annotationsRef.child(annotationId).set(annotationData);

    updateAnnotation = (annotationId, annotationData) => this.annotationsRef.child(annotationId).update(annotationData);

    updateWidget = (widgetId, data) => this.widgetRef.child(widgetId).update(data);

    deleteAnnotation = (annotationId) => this.annotationsRef.child(annotationId).remove();

    getAnnotation = (annotationId) => this.annotationsRef.child(annotationId).once('value');

    getAnnotations = (docId) => this.annotationsRef
      .orderByChild('docId')
      .equalTo(docId)
      .once('value')
      .then(R.invoker(0, 'val'))


    setLock = (val) => this.lockRef.set(val);

    getLock = () => this.lockRef.once('value').then((data) => data.val());


    updateAuthor = (authorId, authorData) => this.authorsRef.child(authorId).update(authorData);

    updateParticipant = (authorId, authorData) => this.authorsRef.child(authorId).update(authorData);

    getInitialAnnotations = async (docId) => {
      const annotsSnapshot = await this.annotationsRef.once('value');

      return _.chain(annotsSnapshot.val())
        .filter((annot) => {
          if (docId) {
            return annot.docId === docId;
          }

          return true;
        })
        .value();
    };

    saveXfdf = (docId, xfdf) => this.xfdfRef.child(docId).set(xfdf)

    getDocXfdf = (docId) => this.xfdfRef.child(docId).once('value').then(R.invoker(0, 'val'));

    getXfdf = () => this.xfdfRef.once('value').then(R.invoker(0, 'val'));
    setXfdf = (val) => this.xfdfRef.set(val);

    getModifiedXfdf = () => this.modifiedXfdf.once('value').then(R.invoker(0, 'val'));

    showCompleting = (val) => this.completingRef.set(val);
    hideCompleting = (val) => this.completingRef.remove();

    setSelectedDocId = (docId) => this.selectedDocIdRef.set(docId);

    getSelectedDocId = () => this.selectedDocIdRef.once('value').then(R.invoker(0, 'val'))

    createSignatures = (signerId, data) => this.consumerSignatures.child(signerId).set(data);

    getSignatures = (signerId) => this.consumerSignatures.child(signerId).once('value')
      .then(R.invoker(0, 'val'))

    deleteSignature = (signerId, type) => this.consumerSignatures.child(signerId).child(type).remove();

    deleteAllSignatures = () => this.consumerSignatures.set({});


    setJoined = async (pId, joined) => {
      this.authorsRef.child(`${pId}/joined`).set(joined);
    }


    markReady = async (runId) => {
      const authorsSnapshot = await this.authorsRef.once('value');
      const authors = authorsSnapshot.val();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const markReady = R.apply(R.useWith(R.unapply(R.identity), [R.identity, R.assoc('status', 'ready')]));
      const setUsersReady = R.pipe(
        R.toPairs,
        R.filter(R.pipe(R.nth(1), R.propEq('runId', runId))),
        R.map(markReady),
        R.fromPairs
      );

      const onDevice = setUsersReady(authors);

      return this.authorsRef.update(onDevice);
    }

    setStatus = async (status) => this.statusRef.set(status);

    getStatus = async () => this.statusRef.once('value').then(R.invoker(0, 'val'));


    saveModifiedXfdf = (docId, xfdf) => this.modifiedXfdf.child(docId).set(xfdf);

    clearModifiedXfdf = () => this.modifiedXfdf.set({});

    getLoadedDocs = async () => this.loadedDocsRef.once('value')
      .then(R.invoker(0, 'val'))
      .then(R.defaultTo([]))

    addLoadedDocs = async (docId) => {
      const loadedDocs = (await this.getLoadedDocs() || []);

      return this.loadedDocsRef.set(_.filter(_.uniq([...loadedDocs, docId]), (el) => !_.isNil(el)));
    }

    clearLoadedDocs = async () => this.loadedDocsRef.set({})


    addPresences = async (signers) => Promise.map(signers, (s) => this.addPresence(s))

    addPresence = async (user, options = {}) => {
      const ref = this.authorsRef.child(user.id);


      console.log('serverOpts.runId', serverOpts.runId)
      const snapshot = await ref.once('value');


      if (this.signerLocation === 'remote') {
        if (snapshot.exists()) {
          const value = snapshot.val();

          if (value.connected && value.runId !== serverOpts.runId && !options.isAdminUser) {
            // eslint-disable-next-line max-len
            const errMsg = 'You are already authenticated on a different device. Please continue your session on that device or close the other web browser window to continue on this device.';
            const err = new Error(errMsg);

            throw err;
          }
        }
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      await ref
        .onDisconnect()
        // .update({
        //   connected: false,
        //   runId: null,
        //   connectedAt: null,
        //   joined: null,
        //   ready: null,
        // });
        .remove();

      // The promise returned from .onDisconnect().set() will
      // resolve as soon as the server acknowledges the onDisconnect()
      // request, NOT once we've actually disconnected:
      // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

      // We can now safely set ourselves as 'online' knowing that the
      // server will mark us as offline once we lose connection.
      return ref.set(filterSignerProps({
        ...user,
        connected: true,
        runId: serverOpts.runId,
        connectedAt: +new Date(),
      }));
    }

    setParticipantStatus = (nsUserId, status) => this.participantsRef
      .child(nsUserId)
      .update({ status });

    setAuthorStatus = (nsUserId, status) => this.authorsRef
      .child(nsUserId)
      .update({ status });

    setShowEnoteConsentModal = (val) => this.enoteConsentModalRef.set(val);

  }


  const server = new Server(serverOpts);

  await server.init();

  return server;
};

export default initServer;
