import React, { useState } from 'react';
import * as R from 'ramda';
import _ from 'lodash';
import Promise from 'bluebird';
import debug from 'debug';
import { useAsync } from 'react-use';
import { useServer } from './hooks/useServerProvider';
import useAppState from './hooks/useAppState';
import { blackInkOnlyText } from '@enotarylog/tag-utils';

import {
  importFbaseVal,
  delFbaseVal,
  importWidgetFbaseVal,
  delWidgetFbaseVal,
  importField,
  setBlankPages,
  lockWebviewer,
  nsCompleting,
  setSelectedSigner,
  setCorrAnnot,
} from './lib/helpers/import';
import Viewer from './viewer';
import * as toolConfigs from './lib/configs';
import signatureResetOnOtherDocs from './lib/helpers/signatureResetOtherDocs';
import signerListWithNotary from './lib/helpers/signerListWithNotary';
import { useApi } from '@enotarylog/shared';
import { autoTagEnoteSignatureInitials } from './lib/annots/stampTag';

const log = debug('collab');


const invokeServerMethod = (fn) => R.pipe(
  R.tap((args) => log('annotation created', args)),
  R.converge(fn, [R.prop('id'), R.identity])
);

const tapP = (fn) => async (args) => {
  fn(args);
  return args;
};




function CollabComponent(props) {
  const server = useServer();
  const appState = useAppState();
  const api = useApi();
  const [switchDisabled, setSwitchDisabled] = useState(true);


  const { loading } = useAsync(async () => {
    const sessionStatus = await server.getStatus();

    // if no doc set, then it to the first one
    // if the session is not in progress, then set the first doc
    if (sessionStatus !== 'in_progress' && appState.isAdminUser) {
      // first we order the docs by their order field
      const docsOrdered = _.sortBy(Object.values(appState.docs), (f) => f.order);
      // then we get the first doc
      const firstDoc = docsOrdered[0].id;

      appState.setSelectedDoc(firstDoc);
      await server.setSelectedDocId(firstDoc);
    }

    server.bind('onSelectedDocIdChanged', appState.selectedDoc, ({ val }) => {
      appState.setSelectedDoc(val);
    }, 'main');
  });

  // Only allow black ink for signatures if an eNote exists in transaction
  // This only affects the Typed Signature and its drop down when first setting signature
  const customSignatureColors = Object.keys(appState.docs).filter(key => appState.docs[key].isEnote).length > 0 ? blackInkOnlyText : null;



  return !loading ? (
    <Viewer
      {...props}
      getToken={props.getToken}
      customSignatureColors={customSignatureColors}
      /*
       * appState
       */
      notary={appState.notary}
      config={appState.config}
      toolConfig={toolConfigs[props.userType]}
      user={appState.user}
      currentUser={appState.currentUser}
      isAdminUser={appState.isAdminUser}
      signers={signerListWithNotary(_.values(appState.getSigners() || {}))}
      docs={props.nsDocs ? {...appState.docs, ...props.nsDocs} : appState.docs}
      runId={appState.runId}
      selectedDoc={(props.showDoc || props.userType === 'admin') && appState.selectedDoc}
      selectedSigner={appState.selectedSigner || '-1'}
      blankPages={appState.blankPages[appState.getSelectedDoc()]}
      signatures={appState.signatures}
      images={appState.images}
      showVaDisclaimer={appState.showVaDisclaimer}
      xfdf={appState.xfdf}
      loadedDocs={appState.loadedDocs}
      switchDisabled={switchDisabled}
      nsId={appState?.notarySession?.id || props?.notarySession?.id}


      /**
       * The following props synchronize data in firebase with webviewer
       */
      getLoadedDocs={() => server.getLoadedDocs()}

      // bind non-doc-specific listeners for downstream updates; when firebase pushes data to browser, update webviewer
      // NOTE: events in here may be unbound in viewer.js! These events are only bound once!
      bindEvents={async (inst) => {
        await Promise.all([
          server.bind('onLockChanged', inst.getDocId(), lockWebviewer(inst), 'main'),
          server.bind('onSelectedSignerChanged', inst.getDocId(), setSelectedSigner(inst, appState), 'main'),
          server.bind('onVaDisclaimerChanged', inst.getDocId(), ({ val }) => appState.setShowVaDisclaimer(val), 'main'),
          server.bind('onConsumerSignaturesChanged', inst.getDocId(), ({ val }) => {
            appState.setSignatures({ ...appState.signatures, ...(val || {}) });
          }, 'main'),
          server.bind('onLoadedDocsChanged', inst.getDocId(), ({ val }) => {
            appState.setLoadedDocs(val || []);
          }, 'main'),
          server.bind('onCompletingChanged', inst.getDocId(), nsCompleting(inst), 'main'),
        ]);
      }}
      unbindEvents={async () => {
        setSwitchDisabled(true);
        await server.unbindAll('all')
      }}

      // bind doc-specific listeners for downstream updates; when firebase pushes data to browser, update webviewer
      bindDocEvents={async (inst) => {
        await Promise.all([
          // server.addLoadedDocs(inst.getDocId()),
          server.bind('onAnnotationCreated', inst.getDocId(), importFbaseVal(inst, server, api)),
          server.bind('onAnnotationUpdated', inst.getDocId(), importFbaseVal(inst, server, api)),
          server.bind('onAnnotationDeleted', inst.getDocId(), delFbaseVal(inst)),
          server.bind('onWidgetCreated', inst.getDocId(), importWidgetFbaseVal(inst)),
          server.bind('onWidgetUpdated', inst.getDocId(), importWidgetFbaseVal(inst)),
          server.bind('onWidgetDeleted', inst.getDocId(), delWidgetFbaseVal(inst)),
          server.bind('onFieldAdded', inst.getDocId(), importField(inst)),
          server.bind('onFieldUpdated', inst.getDocId(), importField(inst)),
          server.bind('onBlankPagesChanged', inst.getDocId(), R.pipeP(
            tapP(setBlankPages(inst)),
            tapP(({ val, key }) => {
              appState.setBlankPages({ ...appState.blankPages, [key]: val });
            })
          )),
        ]);
        setSwitchDisabled(() => false);
      }}
      unbindDocEvents={async () => {
        setSwitchDisabled(true);
        await server.unbindAll();
      }}


      // upstream updates; when webviewer emits changes, push it up to firebase
      onAddDocLoaded={async (docId, instance) => {
        if (appState.isAdminUser) {
          server.addLoadedDocs(docId);
        }
      }}
      onAnnotationAdded={R.juxt([invokeServerMethod(server.createAnnotation), setCorrAnnot(server)])}
      onAnnotationUpdated={R.juxt([invokeServerMethod(server.updateAnnotation), setCorrAnnot(server)])}
      onAnnotationDeleted={R.juxt([invokeServerMethod(server.deleteAnnotation), setCorrAnnot(server, 'delete')])}
      onAnnotationsLoaded={async (instance) => {
        const docId = instance.getDocId()
        const docs = { ...props.nsDocs, ...appState.docs };
        if (appState.isAdminUser) {

          // if enote loaded
          if (docs[docId] && docs[docId].isEnote) {
            const enoteTags = instance.annotManager.getAnnotationsList();
            const autoTags = _.filter(enoteTags, annot => annot.CustomData.forEnote === true);
            // if tags werent automatically placed for each signature field
            if (autoTags.length === 0){
              const ns = appState?.notarySession || props?.notarySession;
              if (ns && ns.enoteId) {
                const { data } = await api.getEoData(ns.enoteId)
                const createSig = autoTagEnoteSignatureInitials(instance);

                const nsUsers = _.values(appState.signers);

                const annots = _.filter(_.flatten(await Promise.all(_.map(data.signers, (signer) => {
                  return Promise.all(_.map(signer.signatures, (sig) => {
                    if (sig.isSigned === true) {
                      return ;
                    }

                    const nsUser = _.find(nsUsers, { enoteUserId: signer.id });
                    return createSig(sig, nsUser.id);
                  }))
                }))), (el) => !_.isNil(el));



                if (annots.length > 0) {
                  await instance.annotManager.deleteAnnotations(instance.annotManager.getAnnotationsList())
                  await instance.annotManager.addAnnotations(annots, false);
                }
              }
            }
          }
        }

        if (props.onAnnotationsLoaded) {
          return props.onAnnotationsLoaded(instance, docs[docId]);
        }
      }}
      onWidgetAdded={invokeServerMethod(server.createWidget)}
      onWidgetUpdated={invokeServerMethod(server.updateWidget)}
      onWidgetDeleted={invokeServerMethod(server.deleteWidget)}
      onLockChanged={server.setLock}

      onRemoveFormFields={server.clearWidgets}
      onRemoveAllAnnots={() => server.resetSession(appState.selectedDoc, true)}
      onRemoveSignatures={async (userId, docId, type) => {
        const annotsToAudit = await signatureResetOnOtherDocs(userId, docId, server, type);

        annotsToAudit.forEach(() => {
          const auditTrail = [
            {
              type: 'USER_REMOVED_SIGNATURE',
              data: {
                nsId: appState?.notarySession?.id || props?.notarySession?.id,
                nsUserId: userId,
                userType: 'signer',
                action: 'deleted',
                docId,
                docTitle: appState.docs[docId].title,
              },
            },
          ];

          props.onAuditTrail(...auditTrail);
        });
      }}
      onSelectedSignerChanged={R.pipeP(
        tapP(server.setSelectedSigner),
        tapP(props.onSelectedSignerChanged || R.identity)
      )}
      onVaDisclaimerChanged={(selectedSigner, show) => {
        if (!selectedSigner || selectedSigner === '-1' || !show) {
          return server.setShowVaDisclaimer(false);
        }

        const signer = appState.signers[selectedSigner];

        if (signer && signer.connected && signer.runId) {
          server.setShowVaDisclaimer(signer.runId);
        }
      }}

      onSaveSignature={server.createSignatures}

      onBlankPagesAdded={(docId, currBlankPages) => server.setBlankPages(docId, currBlankPages)}
      onBlankPagesRemoved={(docId, currBlankPages) => server.setBlankPages(docId, Math.max(currBlankPages, 0))}

      onFieldUpdated={async ({ name, value, docId, widget }) => {
        if (!widget || !widget.CustomData.id || !value) {
          return;
        }

        await server.setField(widget.CustomData.id, {
          docId,
          name,
          value,
        });
        await server.updateWidget(widget.CustomData.id, {
          fieldName: name,
          fieldValue: value,
        });
      }}

      onDocumentChanged={R.pipeP(
        R.juxt([
          ({ newDocId }) => server.setSelectedDocId(newDocId),
        ])
      )}
      createAnnotation={server.createAnnotation}


      onAuditTrail={(...args) => props.onAuditTrail(...args)}
    />
  ) : null;
}

CollabComponent.defaultProps = {
  onAnnotationAdded: R.identity,
  onAnnotationUpdated: R.identity,
  onAnnotationDeleted: R.identity,
};


const composeComponent = R.compose(
  R.identity
);


export const Collab = composeComponent(CollabComponent);
export default Collab;

