export const documentCompletionTags = [
    'NotarySealAnnotation',
    'Signature',
];

const getHasNotarySealedAndSigned = (instance) => {
    const annotListed = instance?.annotManager?.getAnnotationsList();
    const notary = instance.getNotary();
    const notaryId = notary?.userId;
    const filteredAnnots = annotListed.filter(e => {
      return e?.Author === notaryId || e?.Subject === 'NotarySealAnnotation';
    });
    return documentCompletionTags.every(value => {
      return filteredAnnots.find(e => e?.Subject === value);
    });
}

export default getHasNotarySealedAndSigned;