// ADD BACK whiteout and strikethrough annots onto a completed doc
import _ from 'lodash';


function removeAllChildNodes(parent) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

export function addSpecialAnnots(modifiedXfdf, originalXfdf) {
  const originalDoc = new DOMParser().parseFromString(originalXfdf, 'application/xml');
  const annots = originalDoc.querySelector('annots');

  if (!annots) {
    return modifiedXfdf;
  }

  const modifiedDoc = new DOMParser().parseFromString(modifiedXfdf, 'application/xml');
  Array.from(modifiedDoc.querySelectorAll('square[subject*="WhiteOutAnnotation"]')).map((annot) => annot.setAttribute('color', '#FFFFFF'));
  // get whiteouts/strikeouts added during session
  const allSpecialAnnots = annots
    .querySelectorAll('square[subject*="WhiteOutAnnotation"], strikeout[subject*="Strikeout"]');
  Array.from(annots.querySelectorAll('square[subject*="WhiteOutAnnotation"]'))
    .map((annot) => annot.setAttribute('color', '#FFFFF'));
    

  // remove all annots from original
  removeAllChildNodes(annots);

  if (!_.isEmpty(allSpecialAnnots)) {
    _.forEach(allSpecialAnnots, (annot) => {
      if (!annot) {
        return;
      }
      // Remove the border
      if (annot.getAttribute('subject') === 'WhiteOutAnnotation') {
        annot.setAttribute('color', '#FFFFFF');
      }

      if (modifiedDoc.getElementsByTagName('annots')[0]) {
        modifiedDoc.getElementsByTagName('annots')[0].prepend(annot);
      } else {
        originalDoc.getElementsByTagName('annots')[0].prepend(annot);
      }
      annot.remove();
    });
  }


  // whiteouts/strikeouts present before the session
  const originalDocW = new DOMParser().parseFromString(originalXfdf, 'application/xml');
  const whiteoutstrikeoutCount = originalDocW.querySelectorAll('square[subject*="WhiteOutAnnotation"], strikeout[subject*="Strikeout"]').length;
  Array.from(originalDocW.querySelectorAll('square[subject*="WhiteOutAnnotation"]')).map((annot) => annot.setAttribute('color', '#FFFFFF'))
  Array.from(originalDocW.querySelectorAll('annots > :not(square[subject*="WhiteOutAnnotation"]):not(strikeout[subject*="Strikeout"])'))
    .map((el) => el.remove());
  // this gets flattened into the document first before finalXfdf is embedded in the final pdf
  const onlywhiteoutsStrikeoutsXfdf = new XMLSerializer().serializeToString(originalDocW);


  const finalXfdf = new XMLSerializer().serializeToString(modifiedDoc.getElementsByTagName('annots')[0] ? modifiedDoc : originalDoc);

  return {
    finalXfdf,
    whiteoutsStrikeoutsCount: whiteoutstrikeoutCount,
    onlywhiteoutsStrikeouts: onlywhiteoutsStrikeoutsXfdf,
  }
}
export default addSpecialAnnots;
