import React, { useReducer, useCallback } from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import debug from 'debug';
import { useList } from 'react-use';

const log = debug('hooks:AppState');

export const AppStateCtx = React.createContext();


const tapFns = R.pipe(
  R.toPairs,
  R.map(
    R.when(
      R.pipe(R.nth(1), _.isFunction),
      R.converge(R.unapply(R.identity), [R.nth(0), ([key, fn]) => R.pipe(R.tap((args) => log(`${key} called`, args)), fn)])
    )
  ),
  R.fromPairs
);


const buildReducer = (spec) => (state, action) => {
  if (action.type in spec) {
    return spec[action.type](state, action);
  }

  return state;
};


const buildSetter = (prop, doMerge = false) => (state, { payload }) => {
  if (doMerge) {
    const data = R.mergeDeepRight(state[prop], payload);

    log(`${prop} modified`, {
      payload,
      before: state[prop],
      after: data
    });


    return ({
      ...state,
      [prop]: data
    })
  }
  return ({
    ...state,
    [prop]: payload,
  })
};


const reducer = buildReducer({
  setNotary: buildSetter('notary'),
  setXfdf: R.identity,
  setSigners: buildSetter('signers', true),
  setBlankPages: buildSetter('blankPages'),
  setSelectedSigner: buildSetter('selectedSigner'),
  setTwilio: buildSetter('twilio'),
  setSelectedDoc: buildSetter('selectedDoc'),
  setCurrentUser: buildSetter('currentUser'),
  setVerifications: buildSetter('verifications'),
  setStatus: buildSetter('status'),
  setShowVaDisclaimer: buildSetter('showVaDisclaimer'),
  setSignatures: buildSetter('signatures'),
  setNotarySession: buildSetter('notarySession'),
  setPinModal: buildSetter('pinModal'),
  setAuthPinModal: buildSetter('authPinModal'),
  setAuthingNsUserId: buildSetter('authingNsUserId'),
  setDocs: (state, { payload }) => ({
    ...state,
    docs: payload,
    selectedDoc: state.selectedDoc || Object.keys(payload)[0],
  }),
});

// Provider
export function AppStateProvider({
  children,
  config,
  userId = '-1',
  isAdminUser,
  runId,
  docs = [],
  userType,
  signerLocation,
  notarySession,
  signers: _signers,
  twilio,
  signatures,
  images,
  selectedDoc: _selectedDoc,
  verifications,
  notary = {},
  showVaDisclaimer = false,
  user,
  permissions,
  whitelabelId,
  rtdbNamespace,
  // selectedDoc,
  ...rest
}) {

  const [state, dispatch] = useReducer(reducer, {
    signers: _signers || {},
    selectedSigner: null,
    twilio,
    currentUser: userId,
    status: rest.status,
    verifications,
    selectedDoc: _selectedDoc,
    runId,
    signatures,
    permissions,
    whitelabelId,
    docs: _.chain(R.indexBy(R.prop('id'), docs))
      .mapValues((val, docId) => {
        const completed = !!_.find(val.revisions, { completed: true })

        return {
          ...val,
          url: !_.isEmpty(val.url) && _.isString(val.url) ? val.url : `${window.location.origin}/api/documents/${docId}/data`,
          completed
        };
      })
      // .mapValues((val) => { })
      .value(),
    images,
    userType,
    isAdminUser,
    notarySession,
    notary,
    signerLocation,
    blankPages: {},
    user,
    rtdbNamespace,
    xfdf: _.mapValues(R.indexBy(R.prop('id'), docs), (val) => {
      const rtn = _.chain(val.revisions)
        .filter({ completed: false })
        .sortBy('versionNumber')
        .head()
        .get('xmlAnnotation')
        .value();

      return rtn;
    }),
    config,
    showVaDisclaimer,
    // loadedDocs: [],

    // notarization request room
    pinModal: null,
    authPinModal: null,
    authingNsUserId: null,
  });

  const buildDispatch = useCallback((action) => (payload) => dispatch({ type: action, payload }), []);
  const buildGetter = useCallback((prop) => () => state[prop], [state]);
  const [loadedDocs, { set: setLoadedDocs, push: addLoadedDocs }] = useList([]);

  const getUsersOnDevice = useCallback(() => {
    const getUsersOnDevice = R.pipe(
      R.toPairs,
      R.filter(R.pipe(R.nth(1), R.propEq('runId', state.runId))),
      R.fromPairs
    );

    return getUsersOnDevice(state.signers);
  }, [state.signers, state.runId]);

  const getVerifications = useCallback(() => {
    return state.verifications || {};
  }, [state.verifications]);

  const context = {
    setBlankPages: buildDispatch('setBlankPages'),
    setSigners: R.pipe(R.omit([notary?.userId]), buildDispatch('setSigners')),
    updateSigners: (signers) => {
      const newSigners = _.map(signers, (s) => ({
        ...(_.find(state.signers, { id: s.id }) || {}),
        ...s,
      }));

      dispatch('setSigners', newSigners);
    },
    getVerifications,
    setVerifications: buildDispatch('setVerifications'),
    setSelectedSigner: buildDispatch('setSelectedSigner'),
    setTwilio: buildDispatch('setTwilio'),
    setSelectedDoc: buildDispatch('setSelectedDoc'),
    setCurrentUser: buildDispatch('setCurrentUser'),
    setStatus: buildDispatch('setStatus'),
    setShowVaDisclaimer: buildDispatch('setShowVaDisclaimer'),
    setSignatures: buildDispatch('setSignatures'),
    setXfdf: buildDispatch('setXfdf'),
    setDocs: buildDispatch('setDocs'),

    setLoadedDocs: (docs) => setLoadedDocs(docs),
    addLoadedDocs: (docId) => addLoadedDocs(docId),
    setNotarySession: buildDispatch('setNotarySession'),
    setNotary: buildDispatch('setNotary'),
    setPinModal: buildDispatch('setPinModal'),
    setAuthPinModal: buildDispatch('setAuthPinModal'),
    setAuthingNsUserId: buildDispatch('setAuthingNsUserId'),

    getBlankPages: buildGetter('blankPages'),
    getSigners: buildGetter('signers'),
    getSelectedSigner: buildGetter('selectedSigner'),
    getSelectedDoc: buildGetter('selectedDoc'),
    getCurrentUser: buildGetter('currentUser'),
    getRunId: buildGetter('runId'),
    getStatus: buildGetter('status'),
    getUsersOnDevice,

    // runId is used to identity which users are on the same machine
    usersOnDevice: getUsersOnDevice(),
    twilio: state.twilio,
    status: state.status,
    runId: state.runId,
    blankPages: state.blankPages,
    signers: state.signers,
    selectedSigner: state.selectedSigner,
    selectedDoc: state.selectedDoc,
    currentUser: state.currentUser,
    userId: state.currentUser,
    isAdminUser: state.isAdminUser,
    config: state.config,
    docs: state.docs,
    user: state.user,
    userType: state.userType,
    verifications: state.verifications,
    signerLocation: state.signerLocation,
    images: state.images,
    xfdf: state.xfdf,
    permissions: state.permissions,
    notary: state.notary,
    signatures: state.signatures,
    showVaDisclaimer: state.showVaDisclaimer,
    loadedDocs: loadedDocs || [],
    notarySession: state.notarySession,
    pinModal: state.pinModal,
    authPinModal: state.authPinModal,
    authingNsUserId: state.authingNsUserId,
    whitelabelId: state.whitelabelId,
    rtdbNamespace: state.rtdbNamespace,
  };

  return (
    <AppStateCtx.Provider value={tapFns(context)}>
      {children}
    </AppStateCtx.Provider>
  );
}
